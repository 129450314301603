.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(255,255,255, .95);

    .loader {
        border: 8px solid #f3f3f3;
        border-radius: 50%;
        border-top: 8px solid #047bf8;
        width: 100px;
        height: 100px;
        margin: auto;
        margin-top: calc(50vh - 50px);
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* wizard */ 
.wizard > .content {
    min-height: 20rem;
    overflow: auto;
    background: #ffffff;
    border: 1px solid #e8ebf1;
    margin-left: 0;
}

.wizard.vertical > .content {
    margin: 0 0 0.5em 1.5%;
    width: 68%;
}

.wizard>.content>.body {
    float: left;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2.5%;
}

.wizard > .actions a {
    color: #fff;
    background-color: #727cf5;
    border-color: #727cf5;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #4e5bf2;
        border-color: #4250f2;
    }
}

.wizard > .actions .disabled a {
    color: #727cf5;
    border-color: #727cf5;
    background-color: transparent;
    padding: .5em 1em;

    &:hover,
    &:focus {
        color: #fff;
        background-color: #727cf5;
        border-color: #727cf5;
    }
}

.form-group {
    &.has-danger {
        .form-control {
            border-color: #ff3366 !important;
        }
    }
    label {
        &.required {
            &::after {
                content: '*';
                color: #ff3366;
            }
        }
    }
}

/* Dropzone */
.body {
    .dropzone {
        min-height: 200px;
    }
}